@use './_colors.scss' as color;

.h1-sb {
    font-family: 'IntroCond-SemiBold', serif;
    font-size: 46px;
    @media only screen and (max-width: 601px) {
        font-size: 26px;
    }
    color: color.$black-main;
    text-decoration: underline;
    text-decoration-color: #F0CFB4;
}

.h2-b {
    font-family: 'IntroCond-Bold-Alt', serif;
    font-size: 28px;
    color: color.$black-main;
    @media only screen and (max-width: 601px) {
        font-size: 24px;
    }
}

.h3-sb {
    font-family: 'IntroCond-SemiBold', serif;
    font-size: 22px;
    color: color.$black-main;
    @media only screen and (max-width: 601px) {
        font-size: 18px;
    }
}

.h3-b {
    font-family: 'IntroCond-Bold-Alt', serif;
    font-size: 22px;
    color: color.$black-main;
}

.h4-b {
    font-family: 'IntroCond-Bold-Alt', serif;
    font-size: 15px;
    color: color.$black-main;
}

.h3-alt {
    font-family: 'IntroCond-Medium-Alt', serif;
    font-size: 22px;
    font-weight: 400;
    color: color.$black-main;
    @media only screen and (max-width: 601px) {
        font-size: 20px;
    }
}