
.contacts{
    color: #FFFFFF !important;
    justify-content: space-around;
    padding-bottom: 20px;
    padding-left: 100px;
    padding-right: 100px;
    .contact-item{
        margin: 12px;
        margin-right: 104px;
        span{
            color: #FFFFFF !important;
        }
        @media only screen and (max-width: 600px) {
            margin-right: 0 !important;
        }
    }
    @media only screen and (max-width: 600px) {
        flex-direction: column;
        padding-left: 0px;
        padding-right: 0px;
        margin-right: 0 !important;
    }
}

.footer{
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    color: #FFFFFF !important;
    .header-text{
        color: #FFFFFF;
    }
}

.social-container{
    padding-top: 30px;
}

.social{
    justify-content: space-around;
    color: #FFFFFF !important;
    .company-name{
        width: 360px;
        color: #FFFFFF !important;
        @media only screen and (max-width: 600px) {
            text-align: center;
        }
    }
    .logo-container{
        @media only screen and (min-width: 1001px) {
            margin-right: 70px;
        }
    }
    .social-media-item{
        color: #FFFFFF !important;
        margin: 8px;
        img{
            height: 40px !important;
        }
    }
    @media only screen and (max-width: 600px) {
        flex-direction: column;
    }
}

.email-id {
    cursor: pointer;
}

.copied {
    display: none;
}

.show-copied {
    display: flex;
    justify-content: center;
}