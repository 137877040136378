
@use './assets/partials/_colors.scss' as color;
@import url('./assets/partials/_colors.scss');
@import url('./assets/partials/_fonts.scss');
@import url('./assets/partials/_buttons.scss');

.bg-black-main{
    background-color: color.$black-main;
}
.bg-white-main{
    background-color: color.$white-main;
}
.bg-grey4{
    background-color: color.$grey4;
}
.bg-brown-dark{
    background-color: color.$brown-dark;
}
.bg-blue{
    background-color: color.$blue;
}
.black-main{
    color: color.$black-main;
}
.brown-dark{
    color: color.$brown-dark;
}
.brown-light{
    color: color.$brown-light;
}
.white-main{
    color: color.$black-main;
}
.grey1{
    color: color.$grey1;
}
.grey2{
    color: color.$grey2;
}
.grey3{
    color: color.$grey3;
}
.grey4{
    color: color.$grey4;
}
.mt-0{
    margin-top: 0px;
}
.m-0{
    margin: 0 !important;
}
.mt-1{
    margin-top: 4px;
}
.mt-2{
    margin-top: 8px;
}
.mt-3{
    margin-top: 12px;
}
.mt-4{
    margin-top: 16px;
}
.mt-8{
    margin-top: 32px;
}

.mb-1{
    margin-bottom: 4px;
}
.mb-2{
    margin-bottom: 8px;
}
.mb-3{
    margin-bottom: 12px;
}
.mb-4{
    margin-bottom: 16px;
}

.pr-2{
    padding-right: 8px !important;
}
.pl-2{
    padding-left: 8px !important;
}
.p-0{
    padding: 0;
}
.pt-4 {
    padding-top: 16px;
}
.pb-2{
    padding-bottom: 8px !important;
}
.pb-4 {
    padding-bottom: 16px !important;
}
.mt-42 {
    margin-top: 42px !important;
}
.mt-64 {
    margin-top: 64px !important;
}
.pb-8 {
    padding-bottom: 32px;
}
.pt-8 {
    padding-top: 32px;
}
.p-4{
    padding: 16px;
}
.plbr-64{
    padding: 0 64px 64px 64px;
}
.plbr-32{
    padding: 0 32px 32px 32px;
}
.text-align-center{
    text-align: center;
}
.m-0{
    margin: 0;
}
.mr-2{
    margin-right: 8px;
}
.ml-2{
    margin-left: 4px !important;
}
.ml-4{
    margin-right: 16px;
}
.ptb-70{
    padding-top: 70px !important;
    padding-bottom: 70px !important;
}
.pt-70{
    padding-top: 70px !important;
}
.pb-70{
    padding-bottom: 70px !important;
}
.w-100{
    width: 100%;
}
.w-auto{
    width: auto !important;
}
.d-flex{
    display: flex;
}
.fw-b{
    font-weight: 600;
}
.fw-n{
    font-weight: 400;
}
.flex-dir-col {
    flex-direction: column;
}

.justify-content-center{
    justify-content: center;
}

.justify-content-space-around{
    justify-content: space-around;
}
.justify-content-space-between{
    justify-content: space-between;
}
.align-items-center{
    align-items: center;
}

.cursor-pointer{
    cursor: pointer;
}

.meet-team, .upcoming-openings{
    padding-left: 50px;
    padding-right: 50px;
}

.loader{
    width: 100vw;
    height: 100vh;
    background-color: color.$black-main;
    z-index: 111111;
    display: flex;
    justify-content: center;
    align-items: center;
    img{
        width: 280px;
        height: 280px;
        @media only screen and (max-width: 600px) {
            width: 200px;
            height: 200px;
        }
    }
}

.no-border{
    border: none;
}

.hide-overflow{
    overflow: hidden;
}

.text-decoration-none {
    text-decoration: none !important;
}

.align-self-center {
    align-self: center;
}