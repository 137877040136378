.numbers-container{
    .numbers{
        justify-content: space-around;
        flex-flow: wrap;
        .number-item{
            margin: 12px;
            .count {
                font-size: 75px;
                font-weight: 700;
            }
        }
        padding-bottom: 30px;
    }
}