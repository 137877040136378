@use "../../assets/partials/colors" as color;

.cover-1-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  @media only screen and (min-width: 1001px) {
    padding: 0 75px !important;
  }
  @media only screen and (max-width: 600px) {
    flex-direction: column !important;
  }
}

.cover-1 {
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  margin-top: 60px;
  width: 55%;
  .h1-sb {
    @media only screen and (max-width: 600px) {
      padding: 0 33px 0 33px;
    }
  }
  span {
    text-decoration: none;
  }
  a {
    width: 100px;
    text-decoration: none;
    text-align: center;
    @media only screen and (max-width: 600px) {
      font-size: 16px;
      width: 120px;
    }
  }
  @media only screen and (max-width: 600px) {
    align-items: center;
    width: 100%;
    margin: 0;
  }
}
.img-container {
  margin-right: 64px;
  img {
    height: 300px;
  }
  @media only screen and (max-width: 600px) {
    display: none !important;
    margin-right: 0 !important;
  }
  &.small {
    width: 100%;
    @media only screen and (max-width: 600px) {
      display: flex !important;
      margin-top: 24px;
      justify-content: center;
    }
    @media only screen and (min-width: 601px) {
      display: none !important;
    }
  }
}

.cover-2 {
  margin-top: 20px;
  padding-bottom: 20px;
  .main-container {
    @media only screen and (min-width: 1001px) {
      padding-right: 95px;
    }
    .img-container {
      .img-1,
      .img-3 {
        width: 30%;
      }
      .img-2 {
        width: 40%;
      }
    }
  }
  .content {
    @media only screen and (max-width: 600px) {
      flex-direction: column;
    }
    .img-container {
      // margin: 8px;
      margin-left: 106px;
      display: flex;
      // justify-content: center;
      margin-right: 0 !important;
      @media only screen and (max-width: 600px) {
        display: none !important;
      }
      img {
        @media only screen and (min-width: 1001px) {
          width: 100%;
        }
        @media only screen and (max-width: 600px) {
          object-fit: scale-down;
          width: 100%;
          height: fit-content;
        }
        @media only screen and (min-width: 601px) and (max-width: 1000px) {
          width: 90%;
        }
      }
      &.small {
        width: 100%;
        @media only screen and (max-width: 600px) {
          display: flex !important;
          margin: 0;
        }
        @media only screen and (min-width: 601px) {
          display: none !important;
        }
      }
    }
    .left-content {
      margin: 12px;
      width: 60%;
      @media only screen and (max-width: 600px) {
        width: 100% !important;
      }
    }
  }
}

.advantages-container {
  padding: 12px;
  display: flex;
  width: 97%;
  justify-self: center;
  position: relative;
  .image-left {
    position: absolute;
    top: 30px;
    left: 0;

    @media only screen and (max-width: 600px) {
      display: none !important;
      margin-right: 0 !important;
    }
  }
  .image-right {
    position: absolute;
    top: 30px;
    right: -18px;

    @media only screen and (max-width: 600px) {
      display: none !important;
      margin-right: 0 !important;
    }
  }
  .image-full {
    position: absolute;
    top: 150px;
    left: auto;
    right: auto;
    z-index: 1;
    @media only screen and (max-width: 600px) {
      display: flex !important;
      margin-top: 24px;
      justify-content: center;
    }
    @media only screen and (min-width: 601px) {
      display: none !important;
    }
  }
  .advantages {
    width: 700px;
    z-index: 2;
    li {
      line-height: 1.7;
    }
    .cover-1 {
      @media only screen and (min-width: 1001px) {
        margin-left: 90px;
      }
    }
    .img-container {
      margin-right: 84px;
    }
    @media only screen and (max-width: 600px) {
      flex-direction: column;
      width: 92% !important;
    }
  }
  @media only screen and (max-width: 600px) {
    width: 94%;
  }
  a {
    width: 90px;
    align-self: center;
  }
}

.hiring-section {
  border-top: 2px solid color.$brown-light;
  padding: 8px;
  padding-top: 30px;
  padding-bottom: 40px;
  .btn-container {
    display: flex;
    justify-content: center;
  }
}

.our-clients {
  flex-flow: wrap;
  display: flex;
  padding: 0 35px;
  .client {
    margin: 24px;
  }
  .img-container-2 {
    @media only screen and (max-width: 600px) {
      flex-direction: column;
      padding: 0;
      margin: 20px;
    }
    img {
      @media only screen and (max-width: 600px) {
        height: 50px !important;
      }
    }
  }
}
.about-section {
  margin-left: 7%;
  width: 86%;
  .cover-1 {
    padding: 16px;
    justify-content: center;
    @media only screen and (max-width: 600px) {
      width: auto;
    }
  }
  .about {
    @media only screen and (max-width: 600px) {
      flex-direction: column;
      padding: 0;
      margin: 20px 0;
    }
    span {
      @media only screen and (max-width: 600px) {
        font-size: 22px;
      }
    }

    .img-container {
      @media only screen and (max-width: 600px) {
        display: flex !important;
        width: 100%;
      }
    }

    .btn-career {
      width: 260px;
      @media only screen and (max-width: 600px) {
        width: auto;
      }
    }
  }
}
.members {
  flex-flow: wrap;
  justify-content: space-around;
  .member-item {
    margin: 12px;
  }
}
