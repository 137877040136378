.services{
    justify-content: space-around;
    flex-flow: wrap;
    @media only screen and (max-width: 600px) {
        flex-direction: column;
        align-items: center;
    }
    .service-item{
        margin: 12px;
        @media only screen and (max-width: 600px) {
            margin: 22px 12px;
        }
        .title{
            text-align: center;
        }
    }
}