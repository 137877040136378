@font-face {
  font-family: 'IntroCond-SemiBold';
  src: local('IntroCond-SemiBold'), url(./assets/fonts/IntroCond-SemiBold.ttf) format('truetype');
}
@font-face {
  font-family: 'IntroCond-Bold-Alt';
  src: local('IntroCond-Bold-Alt'), url(./assets/fonts/IntroCond-Bold-Alt.ttf) format('truetype');
}
@font-face {
  font-family: 'IntroCond-Medium-Alt';
  src: local('IntroCond-Medium-Alt'), url(./assets/fonts/IntroCond-Medium-Alt.ttf) format('truetype');
}

@import url('./App.scss');

body{
  margin: 0;
  padding: 0;
}