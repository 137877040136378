.create-event{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12px;
    form{
        display: flex;
        flex-direction: column;
        width: 40%;
        .input-container{
            display: flex;
            flex-direction: column;
            margin: 12px;
            label{
                font-size: 18px;
            }
            input, textarea{
                padding: 10px 18px;
                margin-top: 8px;
                font-size: 20px;
            }
        }
        .job-item{
            position: relative;
            .cross{
                position: absolute;
                font-size: 20px;
                right: 10px;
                top: 14px;
                color: red;
                cursor: pointer;
            }
        }
        .add-job, .add-event{
            width: 250px;
            &.disabled{
                color: grey;
                cursor: not-allowed;
            }
        }
        @media only screen and (max-width: 601px) {
            width: 95%;
        }
        
    }
}