@use './_colors.scss' as color;
@import url('./_fonts.scss');

.primary-btn-white{
  background-color: color.$white-main;
  font-family: 'CormorantGaramond-SemiBold', serif;
  font-size: 20px;
  color: color.$black-main;
  padding: 10px 20px;
  outline: none;
  border: 1px solid color.$black-main;
  cursor: pointer;
  border-radius: 4px;
  &:hover{
    background-color: color.$black-main;
    color: color.$white-main;
    border: 1px solid color.$white-main;
  }
}

.primary-btn-black{
  background-color: color.$black-main;
  font-family: 'CormorantGaramond-SemiBold', serif;
  font-size: 20px;
  color: color.$white-main;
  padding: 10px 20px;
  outline: none;
  border: 1px solid color.$white-main;
  cursor: pointer;
  border-radius: 4px;
  &:hover{
    background-color: color.$white-main;
    color: color.$black-main;
    border: 1px solid color.$black-main;
  }
  &:disabled{
    cursor: not-allowed;
    background-color: grey !important;
    color: color.$black-main !important;
  }
}

.secondary-btn-white{
  background-color: color.$white-main;
  font-family: 'CormorantGaramond-SemiBold', serif;
  font-size: 18px;
  color: color.$black-main;
  border: 2px solid color.$grey3;
  padding: 6px 20px;
  cursor: pointer;
  text-decoration: none;
  border-radius: 4px;
  &:hover{
    background-color: color.$black-main;
    color: color.$white-main;
  }
}