.admin{
    .no-event{
        img{
            width: 400px;
            @media only screen and (max-width: 601px) {
                width: 300px !important;
            }
        }
    }
    img{
        &:hover{
            filter: opacity(0.5) drop-shadow(0 0 0 rgb(46, 46, 212));
        }
    }
}
.no-underline{
    text-decoration: none !important;
}
.bread-crumb{
    display: flex;
    background-color: white;
    color: black;
    padding: 22px 18px;
    font-size: 20px;
    font-weight: 600;
    .item{
        color: black;
        &:hover{
            color: rgb(75, 75, 163);
            cursor: pointer;
        }
    }
    .disabled{
        cursor:text !important;
        &:hover{
            color: black;
        }
    }
}
.events-container{
    .event-item{
        display: flex;
        flex-direction: column;
        padding: 18px 12px;
        background-color: white;
        margin: 8px;
        width: 50%;

        @media only screen and (max-width: 601px) {
            width: 80%;
        }
        .event-row{
            padding: 12px;
            border-bottom: 1px solid grey;
        }
        .no-border{
            border: none !important;
        }
        .icons{
            img{
                cursor: pointer;
            }
        }
    }
}