.login-container{
    display: flex;
    position: fixed;
    padding: 40px 28px;
    width: 420px;
    left: 45%;
    margin-left: -150px;
    top: 25%;
    margin-top: -100px;
    background: #FFF;
    z-index: 20;
    -webkit-box-shadow:  0px 0px 0px 9999px rgba(0, 0, 0, 0.5);
    box-shadow:  0px 0px 0px 9999px rgba(0, 0, 0, 0.5);
    border-radius: 8px;
    .login{
        width: 100%;
        img{
            cursor: pointer;
        }
        input{
            padding: 14px 12px;
            width: 93%;
            margin: 14px 0;
            font-size: 22 !important;
        }
        input,
        input::placeholder {
            font: 18px sans-serif;
        }
    }
    @media only screen and (max-width: 1001px) and (min-width: 602px) {
        width: 360px;
        left: 43%;
    }
    @media only screen and (max-width: 1170px) and (min-width: 1024px) {
        width: 400px;
        left: 43%;
    }
    @media only screen and (max-width: 601px) {
        width: 300px;
        left: 43%;
    }
}