.careers{
    @media only screen and (min-width: 1001px) {
        // padding: 0 30px;
    }
    
    .image-1{
        display: flex;
        justify-content: center;
        width: 100%;
        padding: 20px 0;
        img{
            width: 90%;
        }
        @media only screen and (max-width: 600px) {
            display: none !important;
            margin-right: 0 !important;
        }
        &.small{
            width: 100%;
            @media only screen and (max-width: 600px) {
                display: flex !important;
                margin-top: 24px;
                justify-content: center;
            }
            @media only screen and (min-width: 601px) {
                display: none !important;
            }
        }
    }
    .career-section-1{
        padding: 80px;
        .btn-container{
            margin-top: 24px;
            a{
                margin-top: 14px;
                text-decoration: none;
                @media only screen and (max-width: 600px) {
                    font-size: 16px;
                }
            }
        }
    }
    .benefits-container{
        margin-top: 70px;
        position: relative;
        .image-left {
            position: absolute;
            top: 30px;
            left: 0;

            @media only screen and (max-width: 600px) {
            display: none !important;
            margin-right: 0 !important;
            }
        }
        .image-right {
            position: absolute;
            top: 30px;
            right: -18px;

            @media only screen and (max-width: 600px) {
            display: none !important;
            margin-right: 0 !important;
            }
        }
        .image-full {
            position: absolute;
            top: 150px;
            left: auto;
            right: auto;
            z-index: 1;
            @media only screen and (max-width: 600px) {
            display: flex !important;
            margin-top: 24px;
            justify-content: center;
            }
            @media only screen and (min-width: 601px) {
            display: none !important;
            }
        }
        .benefits{
            display: flex;
            flex-flow: wrap;
            padding: 18px;
            justify-content: center;
            @media only screen and (max-width: 600px) {
                flex-direction: column;
            }
            .benefit-item{
                margin: 48px;
                @media only screen and (max-width: 600px) {
                    margin: 15px;
                }
                img{
                    @media only screen and (max-width: 600px) {
                        width: 80px !important;
                        height: 80px !important;
                    }
                }
            }
        }
    }
}