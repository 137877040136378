@use '../../assets/partials/colors' as color;
@use '../../assets/partials/_fonts.scss';

.header{
    background-color: color.$black-main;
    width: 100%;
    padding: 20px 0px;
    border-bottom: 1px solid color.$white-main;

    nav{
        display: flex;
        justify-content: center;
        align-items: center;
        color: #FFFFFF;
        .title {
            color: #FFFFFF;
            text-decoration: none;
        }
        div {
            margin: 10px;
        }

        @media only screen and (max-width: 600px) {
            display: none !important;
        }
        @media only screen and (min-width: 1001px) {
            padding: 0 124px;
            justify-content: center;
        }

    }
    .mobile-nav{
        padding: 0 12px;
        color: #FFFFFF;
        justify-content: center;
        .title {
            margin-left: 12px;
        }
        @media only screen and (min-width: 601px) {
            display: none !important;
        }
    }
    .mobile-nav-content{
        background-color: color.$black-main;
        width: 100vw;
        position: fixed;
        height: 100vh;
        z-index: 11;
        top: 0;
        padding: 8px;
        .nav-logo{
            padding: 6px 14px;
        }
    }
    .nav-link{
        padding: 6px 12px;
        @extend .h3-b;
        color: color.$white-main;
        text-decoration: none;
        cursor: pointer;
        &.active{
            color: color.$brown-light; 
            text-decoration: underline;
        }
        @media only screen and (max-width: 601px) {
            padding: 12px !important;
        }
    }
    .border-bottom{
        border-bottom: 1px solid color.$grey2;
    }
}

.show-nav {
    display: flex !important;
  }

.sticky{
    z-index: 99;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}