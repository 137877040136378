.map{
  h2 {
    padding: 0 16px;
  }
  .content {
    display: flex;
    justify-content: center;
    @media only screen and (max-width: 600px) {
      flex-direction: column;
    }
    .left-container{
      display: flex;
      flex-direction: column;
      margin: 160px 80px 0;
      @media only screen and (max-width: 600px) {
        margin: 20px 80px 0;
      }
      p{
        margin: 0;
      }
      .title{
        color: #979797;
      }
      .item {
        border-bottom: 1px solid #D9D9D9;
        padding-bottom: 10px;
        .num {
          color: #25408F;
        }
      }
    }
    .right-container{
      .image-1{
        display: flex;
        justify-content: center;
        width: 100%;
        padding: 20px 0;
        img{
            width: 90%;
        }
        @media only screen and (max-width: 600px) {
            display: none !important;
            margin-right: 0 !important;
        }
        &.small{
            width: 100%;
            @media only screen and (max-width: 600px) {
                display: flex !important;
                margin-top: 24px;
                justify-content: center;
            }
            @media only screen and (min-width: 601px) {
                display: none !important;
            }
        }
    }
    }
  }
}